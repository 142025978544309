<template>
  <main class="page-main-bg" id="taskPage">
    <!-- Start container -->
    <v-container class="main-container position-relative">
      <!---------------------------------------->
      <!---------------Toolbar------------------>
      <!---------------------------------------->

      <Toolbar v-bind="{ breadcrumbs }" />

      <!------------------------------------->
      <!--------------Add task--------------->
      <!------------------------------------->

      <v-slide-y-transition>
        <TaskState
          v-if="$hasPermission('statistics', 'statistics', 'list')"
          statesType="tasks"
        />
      </v-slide-y-transition>

      <!------------------------------------------------------->
      <!--------------Sub header (View done count)------------->
      <!------------------------------------------------------->

      <v-row class="d-block mx-0">
        <!----------------------------------------------------->
        <!--------------Action user for show tasks------------->
        <!----------------------------------------------------->

        <!------------------------------------>
        <!----MultipleSelectionsForDelete----->
        <!------------------------------------>
        <div class="d-flex align-baseline justify-space-between">
          <div class="d-flex align-baseline" style="gap: 1rem">
            <TasksFilter
              :allEmployees="allEmployees"
              @handleFilters="handleFilters"
              @search="handleSearch"
              @getEmployeesNextData="getEmployeesNextData"
            />

            <MultipleSelectionsForDelete
              ref="MultipleSelectionsForDelete"
              v-bind="multipleSelectionsData"
              :showRestoreDeleted="false"
              @changeSelect="selectAllChanged"
              @switched="switched"
            />
          </div>

          <OrderdBy :sort-options="sortOptions" @handleSort="handleFilters" />
        </div>
      </v-row>

      <!--Loader-->
      <loader v-if="isPageLoading" v-bind="loaderProps" />

      <!--no tasks-->
      <no-data v-else-if="!tasks.length" v-bind="noDataProps" />

      <!---------------------------------->
      <!------------All tasks------------->
      <!---------------------------------->

      <v-row class="mb-3 mx-0 client--tasks--table" v-else>
        <dataTableItem
          v-bind="dataTableItems"
          @showDetails="statusSideDetailes"
          @details="showTaskDetails"
          @taskDelete="deleteTask"
          @updateTask="updateTask"
          @getPropertyDataBySearchKey="getPropertyDataBySearchKey($event)"
          @getPropertyNextData="getPropertyNextData"
          @getEmployeesNextData="getEmployeesNextData"
          @resetAllProperties="resetAllProperties"
          @resetRealestatePagination="resetRealestatePagination"
        />
      </v-row>

      <!------------------------------------------>
      <!--------Pagination for all tasks---------->
      <!------------------------------------------>

      <div class="text-center" v-if="pagination.pagesCount > 1">
        <Pagination :pagination="pagination" @onChange="loadTasks" />
      </div>

      <!------------------------------------>
      <!------------Task details------------>
      <!------------------------------------>

      <v-layout v-show="sideDetailes">
        <side-detailes
          :taskData="taskInfo"
          :sideDetailes="sideDetailes"
          @showDetails="statusSideDetailes"
          @deleteTaskFromSideDetails="deleteTask"
          @updateTask="updateTask"
          @hide-task="statusSideDetailes"
          @showTask="showTaskDetails"
        />
      </v-layout>

      <div class="d-flex align-center justify-end ml-md-8 fast-actions">
        <v-btn
          @click="openAddTaskDialog"
          data-test="open-add-modal"
          class="font-weight-bold fast-actions-btn pa-5 font-12"
          color="primary"
          large
        >
          <img
            class="mx-2"
            src="@/assets/img/svgs/001-writing.svg"
            loading="lazy"
          />
          <span class="mb-1">إضافة مهمة</span>
        </v-btn>

        <!----------------------------->
        <!-------Fast actions---------->
        <!----------------------------->
        <fastActions :class="{ 'mb-5': !hasPermission('add') }" />
      </div>
    </v-container>
    <!-- End container -->

    <!-- Add Task Modal -->
    <add-task-modal
      v-if="addTaskDialogState"
      :dialogState="addTaskDialogState"
      :addLoadingStatus="addLoadingStatus"
      :allClients="allClients"
      :allProperties="allProperties"
      :allEmployees="allEmployees"
      :realEstateLoading="realEstateLoading"
      @toggleDialogState="addTaskDialogState = $event"
      @loadClientProperties="loadClientProperties"
      @getClientNextData="getClientNextData"
      @getClientDataBySearchKey="getClientDataBySearchKey"
      @getPropertyNextData="getPropertyNextData"
      @getPropertyDataBySearchKey="getPropertyDataBySearchKey"
      @addNewTask="addTask"
      @setAddLoadingStatus="addLoadingStatus = $event"
    />
  </main>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import fastActions from '@/components/helper/fastActions'
import OrderdBy from '../components/task-manager/OrderdBy.vue'
import {
  taskManagerService,
  MultipleDeletion,
  realEstateService,
  EnvService
} from '@/services'
import TaskState from '@/components/properties/RealEstatStat'
import tasks from '@/constans/tasks'
import multipleSelections from '@/mixins/multipleSelections'
import dataTableItem from '@/components/helper/dataTableItem'
import SideDetailes from '@/components/task-manager/SideDetailes'
import loader from '@/components/helper/loader.vue'
import Toolbar from '@/components/helper/Toolbar'
import MultipleSelectionsForDelete from '@/components/helper/MultipleSelectionsForDelete'
import TasksFilter from '@/components/task-manager/TasksFilter.vue'
import Pagination from '@/components/helper/pagination.vue'
import noData from '@/components/helper/noData.vue'
import '@/styles/material-dashboard/_team.scss'
import '@/styles/material-dashboard/_properties.scss'

export default {
  name: 'tasks',
  mixins: [multipleSelections],
  components: {
    dataTableItem,
    SideDetailes,
    Pagination,
    loader,
    TaskState,
    OrderdBy,
    TasksFilter,
    Toolbar,
    fastActions,
    AddTaskModal: () => import('@/components/modals/AddTaskModal.vue'),
    MultipleSelectionsForDelete,
    noData
  },
  data() {
    return {
      forUnitTest: false,
      ...tasks,
      tasksCount: 0,
      meBtnStatus: false,
      sortOptions: [
        { nameAr: 'الأحدث', nameEn: 'Newest' },
        { nameAr: 'الأقدم', nameEn: 'Oldest' }
      ],
      isPageLoading: true,
      sideDetailes: false,
      tasks: [],
      task: {},
      filterQuery: {},
      addTaskDialogState: false,
      addLoadingStatus: false,
      taskInfo: {},
      propertySearchKey: '',
      clientSearchKey: '',
      employeeMemberSearchKey: '',
      realEstateLoading: false,
      paginationProperty: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      paginationClient: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      paginationEmployees: {
        pageNumber: 1,
        pagesCount: 0,
        pageSize: 10,
        count: 0
      },
      allProperties: [],
      properties: [],
      clients: [],
      allClients: [],
      employees: [],
      allEmployees: []
    }
  },
  computed: {
    ...mapState('accountState', ['user', 'currentEnv']),
    ...mapGetters('accountState', ['isCustomer']),
    loaderProps() {
      return {
        numberOfLines: 5,
        laoderClasses: 'property-col mb-2 shadow-border-radius px-0',
        type: 'list-item-avatar-two-line',
        cols: 12
      }
    },
    noDataConfig() {
      const data = {
        text: !this.meBtnStatus
          ? 'لا توجد مهام مضافه حتي الأن'
          : 'لا توجد مهام مضافه لك حتي الأن',
        subTitle:
          'تكون المهام متاحة في هذه القائمة عند إضافتها عبر نموذج إضافة مهمة',
        height: '50vh'
      }
      return data
    },
    noDataProps() {
      return {
        noDataText: this.noDataConfig.text,
        noDataSubTitle: this.noDataConfig.subTitle,
        height: this.noDataConfig.height,
        noDataIcon: require('@/assets/img/dashboard-icons/bond.png')
      }
    },
    headers() {
      const arr = []
      this.headersArr.map((header) => {
        if (this.checkedHeaders.includes(header.text)) {
          return arr.push(header)
        }
        return null
      })
      return arr
    },
    dataTableItems() {
      return {
        items: this.tasks,
        headers: this.headersArr,
        complaints: true,
        allProperties: this.allProperties,
        realEstateLoading: this.realEstateLoading,
        allClients: this.allClients,
        allEmployees: this.allEmployees
      }
    },
    tasksLength() {
      return this.tasksCount
    },
    dataDialog() {
      return {
        title: 'حذف المهمه',
        message: 'هل أنت متأكد من حذف بيانات هذه المهمه؟',
        options: {
          color: 'red'
        }
      }
    },
    /* Multiple selections */
    // ------------------ //
    deleteTitle() {
      return this.showSelectAll ? 'حذف المهمات' : 'حذف المهمة'
    },
    restoreTitle() {
      return this.showSelectAll
        ? 'استرجاع المهام المحذوفة'
        : 'استرجاع المهمة المحذوفة'
    }
  },
  mounted() {
    this.$emit('updateInformativeTip', this.$route.meta?.informativeTip)
    this.loadTasks()
  },
  methods: {
    ...mapMutations('appState', ['addNotification']),
    hasPermission(action) {
      return !!this.$hasPermission('users', 'tasks', action)
    },
    resetAllProperties() {
      this.allProperties = []
    },
    loadClientProperties(userId) {
      this.resetAllProperties()
      userId && this.loadProperties(userId)
    },
    resetRealestatePagination() {
      this.paginationProperty.pageNumber = 1
    },
    pushNotification(text, color, timeout = 4000) {
      this.addNotification({
        text,
        color,
        timeout
      })
    },
    openAddTaskDialog() {
      if (!this.hasPermission('add')) {
        this.addNotification({
          text: 'ليس لديك صلاحية لإضافة مهام',
          color: 'error'
        })
        return
      }
      this.addTaskDialogState = true
    },
    handleFilters(filters = {}) {
      this.pagination.pageNumber = 1
      this.filterQuery = { ...this.filterQuery, ...filters }
      this.loadTasks()
    },
    handleSearch(search) {
      this.pagination.pageNumber = 1
      this.filterQuery = { ...this.filterQuery, ...search }
      this.loadTasks()
    },
    switched(data) {
      const { name, value } = data
      this.statusRestore = value

      if (name === 'deleted') {
        this.meBtnStatus = false
        if (value) {
          this.filterQuery = { ...this.filterQuery, deleted: true }
          this.loadTasks()
        } else {
          this.filterQuery = { ...this.filterQuery, deleted: false }
          this.loadTasks()
        }
      }
    },
    getEmployeesDataBySearchKey(e) {
      this.employeeMemberSearchKey = e
      if (this.employeeMemberSearchKey) {
        this.loadEmployees()
      }
    },
    getEmployeesNextData() {
      if (
        this.paginationEmployees.pageNumber <
        this.paginationEmployees.pagesCount
      ) {
        this.paginationEmployees.pageNumber += 1
        this.loadEmployees()
      }
    },
    getClientDataBySearchKey(e) {
      this.clientSearchKey = e
      if (this.clientSearchKey) {
        this.loadClients()
      }
    },
    getClientNextData() {
      if (this.paginationClient.pageNumber < this.paginationClient.pagesCount) {
        this.paginationClient.pageNumber += 1
        this.loadClients()
      }
    },
    getPropertyDataBySearchKey({ e, itemData }) {
      if (itemData.client?._id) {
        this.propertySearchKey = e
        this.loadProperties(itemData.client?._id)
      }
    },
    getPropertyNextData(itemData) {
      if (
        this.paginationProperty.pageNumber <
          this.paginationProperty.pagesCount &&
        itemData.client?._id
      ) {
        this.paginationProperty.pageNumber += 1
        this.loadProperties(itemData.client?._id)
      }
    },
    async loadProperties(userId) {
      this.realEstateLoading = true
      let data = ''
      if (this.propertySearchKey) {
        data = `&search=${this.propertySearchKey}`
      } else {
        data = `pageSize=${this.paginationProperty.pageSize}&pageNumber=${this.paginationProperty.pageNumber}`
      }
      const body = {
        account: this.currentEnv?.owner.id,
        user: userId
      }
      try {
        const res = await realEstateService.getRealEstates(body, data)
        this.paginationProperty.pagesCount = Math.ceil(
          res?.data?.count / this.paginationProperty.pageSize
        )
        this.paginationProperty.count = res?.data?.count ?? 0
        this.properties = res?.data?.realEstates ?? []
        this.allProperties = [...this.allProperties, ...this.properties]
      } catch {
        this.pushNotification('خطأ في تحميل العقارات', 'error')
      } finally {
        this.realEstateLoading = false
      }
    },
    async loadClients(query) {
      let data
      if (this.clientSearchKey) {
        data = `search=${this.clientSearchKey}`
      } else {
        data = `pageSize=${this.paginationClient.pageSize}&pageNumber=${this.paginationClient.pageNumber}`
      }
      try {
        const res = await EnvService.getAllClients(
          this.currentEnv?._id,
          query,
          data
        )
        this.paginationClient.pagesCount = Math.ceil(
          res?.data?.count / this.paginationClient.pageSize
        )
        this.paginationClient.count = res?.data?.count ?? 0
        this.clients = res?.data?.users ?? []
        this.allClients = [...this.allClients, ...this.clients]
      } catch {
        this.pushNotification('خطأ في تحميل العملاء', 'error')
      }
    },
    loadEmployees() {
      let params = `&pageSize=${this.paginationEmployees.pageSize}&pageNumber=${this.paginationEmployees.pageNumber}&registered=1`
      if (this.employeeMemberSearchKey) {
        params += `&search=${this.employeeMemberSearchKey}`
      }

      EnvService.getAllEmployee(this.currentEnv._id, params)
        .then((res) => {
          this.paginationEmployees.pagesCount = Math.ceil(
            res?.data?.count / this.paginationEmployees.pageSize
          )
          this.paginationEmployees.count = res?.data?.count ?? 0
          this.employees = res?.data?.users ?? []
          this.allEmployees = [...this.allEmployees, ...this.employees]
        })
        .catch(() => {
          this.addNotification({
            text: 'خطأ في تحميل الموظفين',
            color: 'error',
            duration: 4000
          })
        })
    },
    async updateTask({ item, index, key }) {
      this.task = item
      const taskToUpdated = {
        name: item?.name,
        category: item?.category,
        priority: item?.priority,
        status: item?.status,
        createdBy: item?.createdBy?._id,
        environment: this.currentEnv?._id,
        date: item?.date,
        type: item?.type,
        client: item?.client,
        user: item?.user,
        description: item?.description
      }
      if (item?.assignedTo.length) {
        taskToUpdated.assignedTo = item.assignedTo.map((moderator) => {
          return {
            name: moderator.name,
            _id: moderator?._id
          }
        })
      }
      if (item?.realEstate) {
        taskToUpdated.realEstate = {
          name: item.realEstate.name || item.realEstate.propertyName,
          _id: item.realEstate._id,
          owner: item.realEstate.owner?._id,
          renter: item.realEstate.tenet?._id || undefined,
          moderators: item.realEstate.moderators
        }
      }
      try {
        await taskManagerService.editTask(taskToUpdated, item?._id)
      } catch {
        this.addNotification({
          text: 'حدث خطأ داخلي يرجى المحاولة لاحقا',
          color: 'error'
        })
      } finally {
        this.task = {}
        this.$emit('refresTasks')
      }
    },
    async addTask(newTask) {
      try {
        await taskManagerService.addTask(newTask)
        this.addNotification({
          text: 'تم اضافة المهمه بنجاح',
          color: 'green'
        })
        this.taskInfo = newTask
        this.loadTasks()
        this.addTaskDialogState = false
      } catch {
        this.addNotification({
          text: 'لم يتم اضافة المهمه يرجى المحاولة مره اخرى',
          color: 'error'
        })
      }
    },
    /*    Tasks   */
    // ----------- //
    // Get all tasks and add some filtering
    async loadTasks() {
      const selectedPagination = this.pagination
      const { createdAt } = this.filterQuery
      const query = {
        environment: this.currentEnv._id,
        pageSize: selectedPagination.pageSize,
        sort: {
          createdAt: createdAt ?? -1
        },
        pageNumber: selectedPagination.pageNumber,
        ...this.filterQuery
      }
      if (this.meBtnStatus) {
        query.user = this.user._id
      }

      try {
        this.isPageLoading = true
        const { data } = await taskManagerService.getAllTasks(query)
        this.tasks = data.tasks.map((task) => ({ ...task, checked: false }))
        this.recalculatePagination('pagination', data)
        await this.decrementPageAndLoadTasksIfNeeded()
      } catch {
        this.addNotification({
          text: 'خطأ في تحميل المهام',
          color: 'error'
        })
      } finally {
        this.isPageLoading = false
      }
    },

    // Get tasks for user
    getOwnTasks() {
      this.pagination.pageNumber = 1
      this.meBtnStatus = !this.meBtnStatus
      this.loadTasks()
    },
    async decrementPageAndLoadTasksIfNeeded() {
      if (!this.tasks.length && this.pagination.pageNumber > 1) {
        this.pagination.pageNumber -= 1
        await this.loadTasks()
      }
    },
    // Delete task
    // eslint-disable-next-line consistent-return
    async deleteTask(itemId) {
      // 1 - Close side details when user want delete task
      this.statusSideDetailes(false)
      // 2 - If not has permission will show notification and don't complete all actions
      if (!this.hasPermission('delete')) {
        return this.addNotification({
          text: 'ليس لديك الصلاحية لحذف المهمه',
          color: 'error'
        })
      }
      // 3 - Delete or not
      try {
        const confirm = await this.popUpConfirm(this.dataDialog)
        // If user agree delete a task
        if (confirm) {
          await taskManagerService.deleteTask(itemId)
          this.loadTasks()
          this.addNotification({
            text: 'تم حذف المهمه بنجاح',
            color: 'success'
          })
        } else {
        }
      } catch {
        this.addNotification({
          text: 'تعذر حذف المهمه',
          color: 'error'
        })
      }
    },
    // Restore tasks with multiple selections
    async restoreDelete() {
      const message = this.showSelectAll
        ? `هل انت متاكد من استرجاع المهام المحذوفة وعددهم ${this.getAllChecked.length}`
        : 'هل انت متاكد من استرجاع المهمة المحذوفة'
      const status = await this.$root.$confirm.open({
        title: this.restoreTitle,
        statusRestore: true,
        message,
        options: {
          color: 'red'
        }
      })
    },
    /*    Pagination   */
    // --------------- //
    // Recalculate pagination handel all pagination in this page
    recalculatePagination(type, res) {
      this[type].pagesCount = Math.ceil(res.count / this[type].pageSize)
      this[type].count = res.count
    },
    // This function will handel back page in pagination if user delete last item
    handelPaginationWhenDelete(type, typeData, pagin) {
      if (
        (type === 'done' || 'noDone') &&
        this[typeData].length === 1 &&
        this[pagin].pageNumber > 1
      ) {
        this[pagin].pageNumber -= 1
      }
    },
    /*   Side details   */
    // --------------- //
    // Get data when user clicked on row and open side details and view this data
    showTaskDetails(taskInfo) {
      this.statusSideDetailes(true)
      this.taskInfo = taskInfo
    },
    // Change status side details open and close
    statusSideDetailes(status) {
      this.sideDetailes = status
    },
    /* Multiple selections */
    // ------------------ //
    // Delete tasks with multiple selections
    async deleteItems() {
      const message = this.showSelectAll
        ? `هل انت متاكد من حذف المهمات وعددهم ${this.getAllChecked.length}`
        : 'هل انت متاكد من حذف المهمة'
      // 1 - Open popup and confirm delete or not
      const status = await this.$root.$confirm.open({
        title: this.deleteTitle,
        message,
        options: {
          color: 'red'
        }
      })

      // If confirmed
      if (status) {
        try {
          if (this.$refs?.MultipleSelectionsForDelete) {
            this.$refs.MultipleSelectionsForDelete.selectAll = false
          }
          const body = { ids: this.getAllChecked }
          await MultipleDeletion.deleteTasks(body)
          this.loadTasks()
          this.addNotification({
            text: 'تم الحذف بنجاح',
            color: 'success'
          })
        } catch {
          this.addNotification({
            text: 'حدث خطا يرجى المحاولة مره اخرى',
            color: 'error'
          })
        }
      }
    },
    async popUpConfirm(data) {
      return await this.$root.$confirm.open(data)
    }
  },
  created() {
    this.loadClients()
    this.loadEmployees()
    if (this.isCustomer) {
      this.loadProperties(this.user?._id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/fastactions.scss';

.dataTaskTable.hectar-table.noHeader
  .v-data-table__wrapper
  table
  .v-data-table-header {
  opacity: 1;
  display: table-header-group;
}

.client--tasks--table {
  ::v-deep {
    table {
      tbody {
        tr {
          td {
            .v-btn--icon.v-size--default {
              width: auto;
            }
          }
        }
      }
    }
  }
}
</style>
