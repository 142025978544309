export default {
  breadcrumbs: [{ text: ' المهام', disabled: true }],
  headersArr: [
    {
      text: 'تحديد',
      align: 'start',
      value: 'checked',
      width: 20,
      sortable: false
    },
    {
      text: 'إسم المهمة',
      align: 'start',
      value: 'name',
      width: 130,
      sortable: false
    },
    {
      text: 'التصنيف',
      value: 'category',
      align: 'center',
      width: 30,
      sortable: false
    },
    {
      text: 'النوع',
      value: 'type',
      width: 40,
      align: 'center',
      sortable: false
    },
    {
      text: 'اسم العميل',
      value: 'client',
      align: 'center',
      width: 95,
      sortable: false,
      show: true
    },
    {
      text: 'إسم العقار',
      value: 'realEstate',
      align: 'center',
      width: 120,
      sortable: false
    },
    {
      text: 'المسئول',
      value: 'assignedTo',
      align: 'center',
      width: 140,
      sortable: false
    },
    {
      text: 'تاريخ الإنشاء',
      value: 'createdAt',
      align: 'center',
      width: 60,
      sortable: false
    },
    {
      text: 'الأولوية',
      value: 'priority',
      align: 'center',
      width: 60,
      sortable: false
    },
    {
      text: 'الحاله',
      value: 'status',
      align: 'center',
      width: 50
    },
    { text: '', value: 'actions', align: 'left', sortable: false }
  ],
  checkedHeaders: [
    'إسم المهمة',
    'المسئول',
    'التصنيف',
    'النوع',
    'إسم العقار',
    'اسم العميل',
    'المسئول',
    'تاريخ المهمه',
    'الأولوية',
    'الحاله',
    ''
  ],
  pagination: {
    pageNumber: 1,
    pagesCount: 0,
    pageSize: 10,
    count: 0
  },
  paginationForDoneTasks: {
    pageNumber: 1,
    pagesCount: 0,
    pageSize: 5,
    count: 0
  },
  arrowDown: require('@/assets/img/svgs/arrowDown.svg')
}
